import React, { useContext, useState } from "react";
import { PatientContext, PreferredLanguageContext, ThemeContext } from "../../lib/main-context";
import { INVITE_THERAPIST } from "../../constants/DashboardTranslation";

import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import inviteTPSchema from "./validations/inviteTPSchema";

import theme from "../../index.scss";
import LabelledInput from "../LabelledInput";
import SubmitButton from "../StyledComponents/SubmitButton";

import {
  sendTherapistEmailFromProvider,
  sendTherapistEmailFromPatient,
  sendTherapistEmailFromCg,
  sendConnectExistingTherapistEmail,
  checkTherapistEmailExisting,
} from "../../api/helpReq";

const InviteTherapist = ({ user }) => {
  const { language } = useContext(PreferredLanguageContext);
  const { patient } = useContext(PatientContext);
  const { dark } = useContext(ThemeContext);

  const [inputVisible, setInputVisible] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [message, setMessage] = useState("");

  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(inviteTPSchema),
  });
  const onSubmit = (data) => {
    setSubmitDisabled(true);

    const formData = {
      ...data,
      therapistEmail: data.therapistEmail.toLowerCase(),
    };
    checkTherapistEmailExisting({ formData, userType: user.userType })
      .then((res) => {
        if (res.data.exist == "false") {
          if (user.userType == "provider") {
            sendTherapistEmailFromProvider({ formData })
              .then(() => {
                setMessage(INVITE_THERAPIST[language].messages.sent);
                setInputVisible(false);
              })
              .catch((error) => {
                console.error("Error:", error);
              })
              .finally(() => {
                setSubmitDisabled(false);
              });
          } else if (user.userType == "caregiver") {
            sendTherapistEmailFromCg({ formData, patientId: patient.patientId })
              .then(() => {
                setMessage(INVITE_THERAPIST[language].messages.sent);
                setInputVisible(false);
              })
              .catch((error) => {
                console.error("Error:", error);
              })
              .finally(() => {
                setSubmitDisabled(false);
              });
          } else {
            // patient
            sendTherapistEmailFromPatient({ formData })
              .then(() => {
                setMessage(INVITE_THERAPIST[language].messages.sent);
                setInputVisible(false);
              })
              .catch((error) => {
                console.error("Error:", error);
              })
              .finally(() => {
                setSubmitDisabled(false);
              });
          }
        } else if (res.data.exist == "true") {
          sendConnectExistingTherapistEmail({
            formData,
            userType: user.userType,
            patientId: patient.patientId,
          })
            .then(() => {
              setMessage(INVITE_THERAPIST[language].messages.sent);
              setInputVisible(false);
            })
            .catch((e) => {
              if (e.response.status === 409) {
                setMessage(INVITE_THERAPIST[language].messages.refer_patient);
              }
            })
            .finally(() => {
              setSubmitDisabled(false);
            });
        } else if (res.data.exist == "connectionExisting") {
          //existing
          setSubmitDisabled(false);
          setMessage(INVITE_THERAPIST[language].messages.existing_connection);
        } else if (res.data.exist == "existingOtherTypeAccount") {
          //existing provider or caregiver account.
          setSubmitDisabled(false);
          setMessage(INVITE_THERAPIST[language].messages.other_account_type);
        }
      })
      .catch((e) => {
        console.error("Error checkTherapistEmailEmailExisting");
      });
  };

  return (
    <>
      <div className="d-flex justify-content-center">
        <h6
          className="pt-2 pb-2"
          style={{
            width: "75%",
            borderRadius: "20px",
            backgroundColor: dark ? theme.darkModePrimary : "#f7bc12",
            color: dark ? theme.darkModeText : "white",
            textAlign: "center",
            overflowWrap: "break-word",
            cursor: "pointer",
          }}
          onClick={() => setInputVisible(!inputVisible)}
        >
          {INVITE_THERAPIST[language].header[0]}
          <br />
          {INVITE_THERAPIST[language].header[1]}
        </h6>
      </div>
      {inputVisible && (
        <Row className="p-2">
          <Col>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row className="p-2">
                <Col>
                  <LabelledInput
                    required
                    label={INVITE_THERAPIST[language].therapist_email}
                    name="therapistEmail"
                    type="email"
                    errors={errors}
                    inputRef={register}
                  />
                  <SubmitButton type="submit" disabled={submitDisabled}>
                    {INVITE_THERAPIST[language].button}
                  </SubmitButton>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      )}
      {message && (
        <Row className="p-2">
          <Col className="text-center">{message}</Col>
        </Row>
      )}
    </>
  );
};

export default InviteTherapist;
