import axios from "axios";

import apiUrl from "./apiConfig";
const apiKey = process.env.REACT_APP_YOUTUBE_API_KEY;
const channelId = process.env.REACT_APP_YOUTUBE_CHANNEL_ID;

axios.defaults.withCredentials = true;

export const postHelp = (data) => {
  return axios.post(`${apiUrl}/misc/help-ticket/`, data);
};

export const sendWarnEmailToProviderAndTP = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/sendWarnEmailToProviderAndTP`, data, {
    params: { patientId },
  });
};

export const sendConfirmEmail = (data) => {
  return axios.post(`${apiUrl}/sendConfirmEmail`, data);
};

export const checkExistingList = (data) => {
  return axios.post(`${apiUrl}/checkExistingList`, data);
};

export const checkEmailToken = (data) => {
  return axios.post(`${apiUrl}/checkEmailToken`, data);
};

export const deleteEmailToken = () => {
  return axios.delete(`${apiUrl}/deleteEmailToken`);
};

export const sendResetPwEmail = (data) => {
  return axios.post(`${apiUrl}/misc/sendResetPwEmail`, data);
};

export const getYoutubeVideos = () => {
  return fetch(
    `https://www.googleapis.com/youtube/v3/search?key=${apiKey}&channelId=${channelId}&part=snippet,id&order=date&maxResults=50`
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
// for invitation

export const sendConnectExistingTherapistEmail = ({
  data,
  patientId,
  userType,
}) => {
  return axios.post(`${apiUrl}/sendConnectExistingTherapistEmail`, data, {
    params: { patientId, userType },
  });
};

export const sendConnectExistingPatientEmail = ({
  data,
  patientId,
  userType,
}) => {
  return axios.post(`${apiUrl}/sendConnectExistingPatientEmail`, data, {
    params: { patientId, userType },
  });
};

export const sendConnectExistingProviderEmail = ({
  data,
  patientId,
  userType,
}) => {
  return axios.post(`${apiUrl}/sendConnectExistingProviderEmail`, data, {
    params: { patientId, userType },
  });
};

export const checkTherapistEmailExisting = ({ data, userType }) => {
  return axios.post(`${apiUrl}/checkTherapistEmailExisting`, data, {
    params: { userType },
  });
};

export const checkPatientEmailExisting = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/checkPatientEmailExisting`, data, {
    params: { patientId },
  });
};

export const checkProviderEmailExisting = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/checkProviderEmailExisting`, data, {
    params: { patientId },
  });
};

export const sendTherapistEmailFromProvider = ({ formData }) => {
  return axios.post(`${apiUrl}/sendTherapistEmailFromProvider`, formData);
};

export const sendTherapistEmailFromPatient = ({ formData }) => {
  return axios.post(`${apiUrl}/sendTherapistEmailFromPatient`, formData);
};

export const sendTherapistEmailFromCg = ({ formData, patientId }) => {
  return axios.post(`${apiUrl}/sendTherapistEmailFromCg`, formData, {
    params: { patientId },
  });
};

// export const sendTherapistEmailFromPatient = ({ data, patientId, userType }) => {
//   return axios.post(`${apiUrl}/sendTherapistEmailFromPatient`, data, {
//     params: { patientId, userType },
//   });
// };

export const sendPatientEmail = ({ formData, patientId, userType }) => {
  return axios.post(`${apiUrl}/sendPatientEmail`, formData, {
    params: { patientId, userType },
  });
};

export const sendPatientEmail2 = ({ formData, patientId, userType }) => {
  return axios.post(`${apiUrl}/sendPatientEmail2`, formData, {
    params: { patientId, userType },
  });
};

export const sendProviderEmail = ({ formData, patientId, userType }) => {
  return axios.post(`${apiUrl}/sendProviderEmail`, formData, {
    params: { patientId, userType },
  });
};

export const sendConnectExistingProviderEmail2 = ({ formData, patientId }) => {
  return axios.post(`${apiUrl}/sendConnectExistingProviderEmail2`, formData, {
    params: { patientId },
  });
};

export const sendProviderEmail2 = ({ formData, patientId }) => {
  return axios.post(`${apiUrl}/sendProviderEmail2`, formData, {
    params: { patientId },
  });
};

export const creatConnection = (data) => {
  return axios.post(`${apiUrl}/creatConnection`, data);
};

export const creatConnectionForExistingTP = (data) => {
  return axios.post(`${apiUrl}/creatConnectionForExistingTP`, data);
};

export const getProviderTokenInformation = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/getProviderTokenInformation`, data, {
    params: { patientId },
  });
};

export const getCaregiverTherapistId = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/getCaregiverTherapistId`, data, {
    params: { patientId },
  });
};

export const getProviderID = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/getProviderID`, data, {
    params: { patientId },
  });
};

export const getPatientID = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/getPatientID`, data, {
    params: { patientId },
  });
};

export const getPatientIDForCgInviteTP = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/getPatientIDForCgInviteTP`, data, {
    params: { patientId },
  });
};

export const getInviterInfo = (data) => {
  return axios.post(`${apiUrl}/getInviterInfo`, data);
};

export const getInviterInfoForExistingTP = (data) => {
  return axios.post(`${apiUrl}/getInviterInfoForExistingTP`, data);
};
