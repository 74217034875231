import React, { useState, useEffect, useContext } from "react";
import { PatientContext, PreferredLanguageContext } from "../../lib/main-context";
import { SYMPTOM_SELECTOR, SYMPTOM_LIST } from "../../constants/OnboardingTranslation";
import { ADD_SYMPTOMS } from "../../constants/DashboardTranslation";

import { useHistory } from "react-router-dom";

import { Row, Col, Form } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import ComponentWrapper from "./ComponentWrapper";
import SubmitButton from "../StyledComponents/SubmitButton";
import UITrackingList from "../StyledComponents/UITrackingList";
import UlPausedList from "../StyledComponents/UlPausedList";
import MedicalListSelector from "../../Components/MedicalListSelector";

import useListSelector from "../../lib/useListSelector";

import {
  postMoreTrackedFactors,
  getTBISymptoms,
  addSymptomDetails,
  getPausedList,
} from "../../api/TBIRequests";

const AddSymptoms = ({ topSymptoms, setTopSymptoms }) => {
  const history = useHistory();
  const { language } = useContext(PreferredLanguageContext);
  const { patient } = useContext(PatientContext);

  const [isAdding, setIsAdding] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [returnedData, setReturnedData] = useState([]);
  const [topSymptomList, setTopSymptomList] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [headacheParts, setHeadacheParts] = useState([]);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [pauseList, setPauseList] = useState([]);
  const [pausedFactorObj, setPausedFactorObj] = useState([]);

  const {
    toggleFromSelectedList,
    switchSingleSelection,
    addOther,
    selectedData,
    initialize,
  } = useListSelector({
    setInitialData: setReturnedData,
    initialData: returnedData,
  });

  const messages = {
    cognitive: `${SYMPTOM_SELECTOR[language].category.cognitive}: `,
    sleep: `${SYMPTOM_SELECTOR[language].category.sleep}: `,
    physical: `${SYMPTOM_SELECTOR[language].category.physical}: `,
    emotional: `${SYMPTOM_SELECTOR[language].category.emotional}: `,
    speech: `${SYMPTOM_SELECTOR[language].category.speech}: `,
    vision: `${SYMPTOM_SELECTOR[language].category.vision}: `,
  };

  const tabs = {
    cognitive: SYMPTOM_SELECTOR[language].category.cognitive,
    sleep: SYMPTOM_SELECTOR[language].category.sleep,
    physical: SYMPTOM_SELECTOR[language].category.physical,
    emotional: SYMPTOM_SELECTOR[language].category.emotional,
    speech: SYMPTOM_SELECTOR[language].category.speech,
    vision: SYMPTOM_SELECTOR[language].category.vision,
  };

  const colors = {
    cognitive: "#edebf3",
    sleep: "#ffdadb",
    physical: "#ffdcaf",
    emotional: "#fff3c4",
    speech: "#cef3c4",
    vision: "#c1fdf7",
  };

  const colorsSelected = {
    cognitive: "#dab7ec",
    sleep: "#ffdadb",
    physical: "#ffdcaf",
    emotional: "#fff3c4",
    speech: "#cef3c4",
    vision: "#c1fdf7",
  };

  useEffect(() => {
    let isMounted = true; // Track whether the component is mounted

    getPausedList(patient?.patientId)
      .then(({ data }) => {
        if (isMounted) {
          const li = [];
          const pausedObj = [];
          data.forEach((i) => {
            if (!li.includes(i.factor)) {
              li.push(i.factor);
              pausedObj.push(i);
            }
          });
          setPauseList(li);
          setPausedFactorObj(pausedObj);
        }
      })
      .catch(() => history.push("/oops"));

    // Cleanup function
    return () => {
      isMounted = false; // Set isMounted to false when the component is unmounted
    };
  }, []);

  const changeErrorMsg = (errorCase) => {
    const errorMsgList = {
      // isOverLimit: "You are already tracking 10 symptoms",
      noSymptom: SYMPTOM_SELECTOR[language].errors.no_symptom.neutral,
      needHeadAcheLocation: SYMPTOM_SELECTOR[language].errors.headache_location,
    };
    setErrorMsg(errorMsgList[errorCase]);
    setShowErrorMsg(true);
  };

  const getUntrackingSymptomsObj = (list) => {
    const unTrackingList = [];
    list.forEach((symptom) => {
      if (
        !topSymptomList.includes(symptom.factor) &&
        symptom.category !== "SDOH"
      ) {
        unTrackingList.push(symptom);
      }
    });
    return unTrackingList;
  };

  const categories = [
    "cognitive",
    "sleep",
    "emotional",
    "physical",
    "speech",
    "vision",
  ];

  const createItemListFromRes = (data) => {
    return categories.map((category) => {
      const items = data
        .filter((item) => item.subcategory === category)
        .map((item) => item.factor);
      items.push("None");
      return { category, items };
    });
  };

  const buildMoreSymptoms = async () => {
    try {
      getTBISymptoms().then(({ data }) => {
        const availableSymptoms = getUntrackingSymptomsObj(data);
        const itemList = createItemListFromRes(availableSymptoms);

        const translatedItemList = itemList.map(itemList => {
          const { category, items } = itemList;
          // Translate each item in the items array
          const translatedItems = items.map(item =>
            SYMPTOM_LIST[language][category][item]
          );
          return { category, items: translatedItems };
        });

        initialize(itemList);
        setReturnedData(translatedItemList);
      });
      setIsAdding(true);
    } catch {
      history.push("/oops");
    }
  };

  const handleClose = () => {
    setIsAdded(true);
  };
  const handleSubmit = () => {
    const selectedSymptomArr = [];
    const sArr = Object.entries(selectedData);
    for (let i = 0; i < sArr.length; i++) {
      const subcategory = sArr[i][0];
      for (let j = 0; j < sArr[i][1].length; j++) {
        const curArr = [subcategory, sArr[i][1][j]];
        selectedSymptomArr.push(curArr);
      }
    }

    if (selectedSymptomArr.length === 0) {
      changeErrorMsg("noSymptom");
      return;
    }

    // else if (selectedSymptomArr.length + topSymptoms.length > 10) {
    //   changeErrorMsg("isOverLimit");
    //   return;
    // }
    setSubmitting(true);

    try {
      selectedSymptomArr.forEach((selectedSymptom) => {
        if (
          selectedSymptom[1] ===
          SYMPTOM_LIST[language].vision["Headaches caused by close work: computers, reading, gaming"]
        ) {
          if (headacheParts.length > 0) {
            addSymptomDetails({
              patientId: patient?.patientId,
              data: {
                symptom:
                  "Headaches caused by close work: computers, reading, gaming",
                detail: headacheParts,
              },
            });
          } else {
            setSubmitting(false);
            changeErrorMsg("needHeadAcheLocation");
            return;
          }
        }
      });

      postMoreTrackedFactors({
        patientId: patient?.patientId,
        data: selectedSymptomArr,
      }).then((addedTopSymptoms) => {
        const newTopSymptoms = [...topSymptoms, ...addedTopSymptoms.data];
        setTopSymptoms(newTopSymptoms);
        setIsAdded(true);
        setSubmitting(false);
      });
    } catch (error) {
      history.push("/oops");
    }
  };

  useEffect(() => {
    if (topSymptoms) {
      let topList = topSymptoms.map((symptom) => symptom.factor);
      setTopSymptomList(topList);
    }
  }, [topSymptoms]);

  return (
    <ComponentWrapper>
      {isAdded ? (
        <Row className="d-flex align-items-center justify-content-center">
          {/* <div className="ml-2">
            New symptom is added to your list to track.
          </div> */}
          <div className="d-flex justify-content-center">
            <h6
              className="pt-2 pb-2 pl-3 pr-3"
              style={{
                width: "100%",
                borderRadius: "20px",
                backgroundColor: "#3EB1A6",
                color: "white",
                textAlign: "center",
                overflowWrap: "break-word",
                cursor: "pointer"
              }}
              onClick={() => {
                setIsAdded(false);
                buildMoreSymptoms();
              }}
            >
              {ADD_SYMPTOMS[language].add_more_symptoms}
            </h6>
          </div>

          {/* <SubmitButton
              onClick={() => {
      
              }}
            >
              Add more
            </SubmitButton> */}

        </Row>
      ) : isAdding ? (
        <>
          <Row>
            <Col sm={12}>
              <UITrackingList
                symptoms={topSymptoms}
                pauseList={pauseList}
                setPauseList={setPauseList}
                pausedFactorObj={pausedFactorObj}
                setPausedFactorObj={setPausedFactorObj}
                setTopSymptoms={setTopSymptoms}
                title={ADD_SYMPTOMS[language].currently_tracking}
              />
            </Col>
            <Col sm={12}>
              <UlPausedList
                symptoms={pauseList}
                pausedFactorObj={pausedFactorObj}
                setPausedFactorObj={setPausedFactorObj}
                setPauseList={setPauseList}
                setTopSymptoms={setTopSymptoms}
                topSymptomsList={topSymptoms}
                title={ADD_SYMPTOMS[language].symptom_paused}
              />
            </Col>
          </Row>
          <Row className="d-flex align-items-center justify-content-center mt-4 mb-4 mr-3">
            <Col md={10}>
              <div className="bubbleText">
                {ADD_SYMPTOMS[language].unpausing[0]}
                <br />
                <br />
                {ADD_SYMPTOMS[language].unpausing[1]}
              </div>
            </Col>
            <Col md={2}>
              <SubmitButton disabled={false} onClick={handleClose}>
                {ADD_SYMPTOMS[language].close}
              </SubmitButton>
            </Col>
          </Row>
          <Col sm={12}>
            <h6 style={{ textAlign: "center" }}>{ADD_SYMPTOMS[language].symptoms}</h6>
          </Col>
          <Tab.Container id="left-tabs-example" defaultActiveKey="cognitive">
            <Row
              style={{
                marginTop: "0.5rem",
                fontWeight: "normal",
                fontSize: "15px",
              }}
            >
              <Col sm={3} style={{ paddingRight: "0px" }}>
                <Nav fill justify variant="tabs" className="flex-column">
                  {returnedData.map((itemList, i) => (
                    <Nav.Item key={i}>
                      <Nav.Link
                        className="navLink"
                        eventKey={itemList.category}
                        style={{
                          background: `${colors[itemList.category]}`,
                          border: "1px solid #106995",
                        }}
                      >
                        {" "}
                        {tabs[itemList.category]}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col sm={5} style={{ paddingRight: "0px" }}>
                <Tab.Content>
                  {returnedData.map((itemList, i) => (
                    <Tab.Pane eventKey={itemList.category} key={i}>
                      <MedicalListSelector
                        addSymptoms={true}
                        patient={patient}
                        category={itemList.category}
                        message={messages[itemList.category]}
                        list={itemList.items}
                        index={i}
                        selectedList={selectedData[itemList.category]}
                        toggleFromSelectedList={toggleFromSelectedList}
                        selectOne={switchSingleSelection}
                        addOther={addOther}
                        setHeadacheParts={setHeadacheParts}
                        headacheParts={headacheParts}
                      />
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
              <Col sm={4}>
                <div
                  className="justify-content-left choseList"
                  style={{
                    height: "30rem",
                    overflow: "scroll",
                    color: "black",
                    border: "1px solid #106995",
                    textAlign: "left",
                    background: "#fff",
                    padding: "1rem",
                    borderRadius: "5px",
                    margin: ".25rem 0",
                  }}
                >
                  {Object.entries(selectedData).flatMap(
                    ([category, itemList]) => {
                      return itemList.map((item, i) => (
                        <div
                          style={{ margin: "0.5rem 0" }}
                          key={`${category}-${i}`}
                        >
                          <svg
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                          >
                            <title>icon/action/check_ccircle_24px</title>
                            <defs>
                              <path
                                d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M10,17 L5,12 L6.41,10.59 L10,14.17 L17.59,6.58 L19,8 L10,17 Z"
                                id="path-1"
                              ></path>
                            </defs>
                            <g
                              id="icon/action/check_ccircle_24px"
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <mask id="mask-2" fill="white">
                                <use xlinkHref="#path-1"></use>
                              </mask>
                              <use
                                id="-↳Color"
                                fill={colorsSelected[category]}
                                xlinkHref="#path-1"
                              ></use>
                            </g>
                          </svg>{" "}
                          {item}
                        </div>
                      ));
                    }
                  )}
                </div>
              </Col>
            </Row>
          </Tab.Container>
          <Row>
            {showErrorMsg && (
              <Form.Control.Feedback
                style={{ display: "block" }}
                type="invalid"
              >
                {errorMsg}
              </Form.Control.Feedback>
            )}
          </Row>
          <SubmitButton disabled={submitting} onClick={handleSubmit}>
            {ADD_SYMPTOMS[language].save_and_add}
          </SubmitButton>
        </>
      ) : (
        <Row className="d-flex align-items-center justify-content-center">
          <div className="d-flex justify-content-center">
            <h6
              className="pt-2 pb-2 pl-3 pr-3"
              style={{
                width: "100%",
                borderRadius: "20px",
                backgroundColor: "#3EB1A6",
                color: "white",
                textAlign: "center",
                overflowWrap: "break-word",
                cursor: "pointer"
              }}
              onClick={buildMoreSymptoms}
            >
              {ADD_SYMPTOMS[language].add_symptoms}
            </h6>
          </div>

          {/* <SubmitButton color={'primary'}}>ADD OR PAUSE Symptoms</SubmitButton> */}

        </Row>
      )}
    </ComponentWrapper>
  );
};

export default AddSymptoms;
