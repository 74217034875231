import React, { useContext, useState } from "react";
import { PreferredLanguageContext, PatientContext, ThemeContext } from "../../lib/main-context";
import { INVITE_PATIENT } from "../../constants/DashboardTranslation";

import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import invitePatientSchema from "./validations/invitePatientSchema";

import theme from "../../index.scss";
import LabelledInput from "../LabelledInput";
import SubmitButton from "../StyledComponents/SubmitButton";

import {
  sendPatientEmail,
  sendPatientEmail2,
  checkPatientEmailExisting,
} from "../../api/helpReq";

const InvitePatient = ({ user }) => {
  const { dark } = useContext(ThemeContext);
  const { patient } = useContext(PatientContext);
  const { language } = useContext(PreferredLanguageContext);

  const [inputVisible, setInputVisible] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [
    existingProviderCaregiverAccount,
    setExistingProviderCaregiverAccount,
  ] = useState(false);
  const [existingPatientAccount, setExistingPatientAccount] = useState(false);
  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(invitePatientSchema),
  });

  const onSubmit = async (data) => {
    // const isInTest = (window.location.hostname === "localhost") || (window.location.hostname.startsWith("test")) ? "True" : "False"
    // data.isInTest = isInTest;
    setSubmitDisabled(true);
    const formData = {
      ...data,
      patientEmail: data.patientEmail.toLowerCase(),
    };
    if (user.userType === "Therapist" || user.userType === "provider") {
      try {
        const res = await checkPatientEmailExisting({
          formData,
          patientId: patient?.patientId,
        });

        if (res.data.exist === "false") {
          if (user.userType === "Therapist") {
            await sendPatientEmail({
              formData,
              patientId: patient?.patientId,
              userType: user.userType,
            });
          } else if (user.userType === "provider") {
            await sendPatientEmail2({
              formData,
              patientId: patient?.patientId,
              userType: user.userType,
            });
          }
          setEmailSent(true);
          setInputVisible(false);
        } else if (res.data.exist === "true") {
          setExistingPatientAccount(true);
        } else if (res.data.exist === "existingOtherTypeAccount") {
          // Existing provider or caregiver account.
          setExistingProviderCaregiverAccount(true);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setSubmitDisabled(false);
      }
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center">
        <h6
          className="pt-2 pb-2 pl-3 pr-3"
          style={{
            width: "75%",
            borderRadius: "20px",
            backgroundColor: dark ? theme.darkModePrimary : "#f7bc12",
            color: dark ? theme.darkModeText : "white",
            textAlign: "center",
            overflowWrap: "break-word",
            cursor: "pointer",
          }}
          onClick={() => setInputVisible(!inputVisible)}
        >
          {INVITE_PATIENT[language].header}
        </h6>
      </div>
      {inputVisible && (
        <Row className="p-2">
          <Col>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row className="p-2">
                <Col>
                  <LabelledInput
                    required
                    label={INVITE_PATIENT[language].patient_email}
                    name="patientEmail"
                    type="email"
                    errors={errors}
                    inputRef={register}
                  />
                  <SubmitButton type="submit" disabled={submitDisabled}>
                    {INVITE_PATIENT[language].button}
                  </SubmitButton>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      )}
      {emailSent && (
        <Row className="p-2">
          <Col className="text-center">
            {INVITE_PATIENT[language].messages.sent}
          </Col>
        </Row>
      )}
      {existingProviderCaregiverAccount && (
        <Row className="p-2">
          <Col className="text-center">
            {INVITE_PATIENT[language].messages.other_account_type}
          </Col>
        </Row>
      )}
      {existingPatientAccount && (
        <Row className="p-2">
          <Col className="text-center">
            {INVITE_PATIENT[language].messages.existing_patient_account}
          </Col>
        </Row>
      )}
    </>
  );
};

export default InvitePatient;
